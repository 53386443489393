import React from "react";
import ReactDOM from "react-dom";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

const GoogleMapComponent = compose(
  withProps({
    /**
     * Note: create and replace your own key in the Google console.
     * https://console.developers.google.com/apis/dashboard
     * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
     */
    // ZG, for knowledge, please refer to 
    // 1. https://tomchentw.github.io/react-google-maps/
    // 2. https://developers.google.com/maps/documentation/javascript/infowindows

    //Test key not working...
    //googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places",
    // ZG's key
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDmALWl0dH2rKCZO843egA6pfVNwl355Os&callback=initMap",

    // googleMapURL:
    //   "https://maps.googleapis.com/maps/api/js?key=AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `50vh` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={15} defaultCenter={{ lat: 1.377116, lng: 103.875025 }}>
   
  <Marker position={{ lat: 1.377116, lng: 103.875025 }} infowindow={{ content:'<strong>X2 Lab Pte Ltd</strong><br />55 Serangoon North Ave 4 #04-01, S9<br />Singapore 555859<br />' }} />
   
  </GoogleMap>
));

export default GoogleMapComponent;