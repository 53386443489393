/* eslint no-unused-vars: 0 */

import { navigate } from "gatsby";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import PropTypes from "prop-types";
import React from "react";
import GoogleMapComponent from "./googlemap";

const FormItem = Form.Item;
const { TextArea } = Input;
import "antd/lib/form/style/index.css";
import "antd/lib/input/style/index.css";
import "antd/lib/button/style/index.css";
import { ThemeContext } from "../../layouts";

const Contact = props => {
  const { getFieldDecorator } = props.form;

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  function handleSubmit(e) {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        sendMessage(values);
      }
    });
  }

  function sendMessage(values) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values })
    })
      .then(() => {
        console.log("Form submission success");
        navigate("/success");
      })
      .catch(error => {
        console.error("Form submission error:", error);
        this.handleNetworkError();
      });
  }

  function handleNetworkError(e) {
    console.log("submit Error");
  }

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <div className="contact-container">
            <div className="con-i form col-12 col-lg-8 col-md-8 col-sm-12 col-xs-12">
            
              <div className="whereare">
              
              <h3>X2 Lab Pte Ltd</h3>
                <div className="emailphone">
                  <h3>Tel: <span className="office-address">+65 6908 0812</span></h3>
                  <h3>Fax: <span className="office-address">+65 6491 6567</span></h3>
                  <h3>Email: <span className="office-address">sales@x2lab.com.sg</span></h3>                  
                </div>

                <h5></h5>
                <p>All inquiries are welcome! We are here to provide you a better service.</p>

                <hr/>

                <h4>We are here</h4>
                <p>55 Serangoon North Ave 4 #04-01,S9, Singapore 555859</p>
              </div>
              <div className="googlemap">
                <GoogleMapComponent></GoogleMapComponent>
              </div>
            </div>
            {/*<div className="con-i form col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="whereare">
                <h3>Contact Us</h3>
                <p>All inquiries are welcome. </p>
              </div>
              <div className="emailphone">
                <h3>X2 Lab Pte Ltd</h3>                
                <h3>Tel: <span className="office-address">(65) 6908 0812</span></h3>
                <h3>Fax: <span className="office-address">(65) 6491 6567</span></h3>
                <h3><br /></h3> 
                <h3>Email: <span className="office-address">sales@x2lab.com.sg</span></h3>
              </div>
        </div>*/}

            {/* --- STYLES --- */}
            <style jsx>{`
              .con-i {
                margin: 0 auto;
                margin-bottom: 20px;
              }
              .emailphone {
                h3 {
                  font-size: 15px !important;
                  opaticy: 0.8 !important;
                  color: black !important;
                }
                .office-address {
                  font-size: 14px !important;
                  color: #0098e4;
                }
                margin-bottom: 25px;
              }
              .whereare {
                margin-bottom: 15px;
                h3 {
                  color: #0098e4;
                  font-size: 24px;
                  margin-bottom: 10px;
                }
                p {
                  font-size: 14px;
                  font-weight: 600;
                  opacity: 0.7;
                }
              }
              .contact-container {
                margin-bottom: 40px !important;
                display: flex !important;
                flex-wrap: wrap;
                max-width: 80em;
                margin: 0 auto;
                margin-top: 60px;
              }
              .form {
                background: transparent;
              }
              .form :global(.ant-row.ant-form-item) {
                margin: 0 0 1em;
              }
              .form :global(.ant-row.ant-form-item:last-child) {
                margin-top: 1em;
              }
              .form :global(.ant-form-item-control) {
                line-height: 1em;
              }
              .form :global(.ant-form-item-label) {
                line-height: 1em;
                margin-bottom: 0.5em;
              }
              .form :global(.ant-form-item) {
                margin: 0;
              }
              .form :global(.ant-input) {
                appearance: none;
                height: auto;
                font-size: 1.2em;
                padding: 0.5em 0.6em;
              }
              .form :global(.ant-btn-primary) {
                height: auto;
                font-size: 1.2em;
                padding: 0.5em 3em;
                background: ${theme.color.brand.primary};
                border: 1px solid ${theme.color.brand.primary};
              }
              .form :global(.ant-form-explain) {
                margin-top: 0.2em;
              }

              @from-width desktop {
                .form :global(input) {
                  max-width: 100%;
                }
              }
              @below tablet {
                .contact-container {
                  display: flex !important;
                  flex-wrap: wrap;
                  max-width: 100em;
                  margin: 0 auto;
                }
              }
            `}</style>
          </div>
        )}
      </ThemeContext.Consumer>
    </React.Fragment>
  );
};

Contact.propTypes = {
  form: PropTypes.object
};

const ContactForm = Form.create({})(Contact);

export default ContactForm;
