import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Contact from "../components/Contact";
import Headline from "../components/Article/Headline";
import Seo from "../components/Seo";
import Categories from "../components/Category";

class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      category: [],
      selectedcat: ''
    }
    this.SelectCategory = this.SelectCategory.bind(this)
  }

  SelectCategory(key) {
  }
  
  componentDidMount() {
    let category = [];
    category.push(
      {
        'title': 'About Us'
      }, 
      {
        'title': 'Contact Us'
      }
    )
    this.setState({
      category: category,
      selectedcat: 'Contact Us'
    })
  }
  render() {
    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <div className="product_container">
              <Categories categories = {this.state.category} selectedcat={this.state.selectedcat} onClick={this.SelectCategory} />
              <Article theme={theme}>  
                <Contact theme={theme} />
              </Article>
              {/* --- STYLES --- */}
              <style jsx>{`
                .product_container {
                  padding: 0rem;
                  margin: 0 auto;
                }
                @from-width desktop {
                  .product_container {
                  }
                }
              `}</style>
            </div>
          )}
        </ThemeContext.Consumer>
      </React.Fragment>
    );
  }
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ContactPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
